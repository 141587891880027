
import React, { useEffect, useState, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";

import Send_icon from '../../images/icons/send_ic.svg';
import Hajira_img from '../../images/hajira.png';
import Muzamil_img from '../../images/muzumil.png';
import Rabbia_img from '../../images/rabbia.png';
import Chat_img from '../../images/chat-img.png';
import Chat_tick from '../../images/chat-tick.svg';
import Chat_seen from '../../images/chat-seen.svg';
import Chat_delay from '../../images/clock.svg';

import Groupcontact from '../group-table/groupcontact';
import AssignContact from './Assign-contact';
import ChatAccordion from './chat-accordion';
import UserBasicInfo from './basic-info';
import UserTags from './usertags';
import UserNotes from './usernotes';
import InsertTemplate from './insert_template';

import Userlist from './userlist';
import Select from 'react-select';

import "./inbox.css";

import Doc_file from "../../images/inbox/doc-file.svg";
import Pdf_file from "../../images/inbox/pdf-file.svg";
import Ppt_file from "../../images/inbox/ppt-file.svg";
import Xls_file from "../../images/inbox/xls-file.svg";
import Chat_banner from "../../images/chat-img.png";
import Eventimg from "../../images/event-img.jpg";
import Reciver from "../../images/icons/reciver.svg";
import Linkforward from "../../images/icons/link-forward.svg";

const Inboxchat = () => {

    const [dtshow, setDtShow] = useState(false);
    const [showMessage,setMessage] = useState(window.innerWidth > 768)
    const [cgtshow, setCgtShow] = useState(false);
    const [atsshow, setAtsShow] = useState(false)
    const [ctshow, setCtShow] = useState(false);
    const [dtmshow, setDtmShow] = useState(false);
    const [muteshow, setMuteShow] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [replyshow, setReplyShow] = useState(false);
    const [editshow, setEditShow] = useState(false);
    const [docshow, setDocShow] = useState(false);
    const [imageshow, setImageShow] = useState(false)
    const [selectTemplatehow, setSelectTemplateShow] = useState(false);
    const [mobselectTemplatehow, setMobSelectTemplateShow] = useState(false);
    const [mobtemplateshow, setMObTemplateShow] = useState(false);

    const refOne = useRef(null);
    const refTwo = useRef(null);

    const showReply = () => {
        setReplyShow(true)
    }

    const hideReply = () => {
        setReplyShow(false)
    }

    const showDoc = () => {
        setDocShow(true)
    }

    const hideDoc = () => {
        setDocShow(false)
    }

    const showImage = () => {
        setImageShow(true)
    }

    const hideImage = () => {
        setImageShow(false)
    }

    // const buttonClick = () => {
    //     setIsActive(true);
    // }

    // const handleInputChange = (event) => {
    //     setInputValue(event.target.value);
    // };   


    useEffect(() => {
        if (refTwo.current){
            refTwo.current.scrollTop = 20000000; // px
        }
     }, [refTwo.current]);


    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);
    
      const handleClickOutside = (event) => {
        if (refOne.current && !refOne.current.contains(event.target)) {
            setIsActive(false);
            setInputValue("");
        }
      };

      useEffect(() => {
        const handleResize = () => {
            setMessage(window.innerWidth > 767);
        };
    
        window.addEventListener("resize", handleResize);
    
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);
      const handleButtonClick = () => {
        if (window.innerWidth < 767) {
            setMessage(true);
        }     

    }

    const options = [
        { value: 'Marketing', label: 'Marketing' },
        { value: 'sale', label: 'Sale' },
        { value: 'queries', label: 'Queries' },
    ]

    const [activeTab, setActiveTab] = useState('tab1'); // State to manage active tab

    const handleTabClick = (tabId) => {
        setActiveTab(tabId); // Update active tab based on tabId
    };

    const [file, setFile] = useState(null);
      
    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
            if (uploadedFile) {
            setFile({
                name: uploadedFile.name,
                size: (uploadedFile.size / 1024).toFixed(2), // size in KB
                type: uploadedFile.type,
            });
        }
    }

    // Determine the icon to display based on the file type
    const renderIcon = () => {
        if (!file) return null;
        
        if (file.type.includes('pdf')) return <img src={Pdf_file} alt="PDF Icon"/>;
        if (file.type.includes('excel') || file.type.includes('sheet')) return <img src={Xls_file} alt="Excel Icon"/>;
        if (file.type.includes('word')) return <img src={Doc_file} alt="Word Icon"/>;
        if (file.type.includes('presentation') || file.type.includes('ppt')) return <img src = {Ppt_file} alt = "Ppt-icon"/>;
        return <img src={Doc_file} alt="Default Icon" />;
    };


    const [image, setImage] = useState(null);
    const ImageInputRef = useRef(null);

    const handleImageUpload = () => {
        ImageInputRef.current.click();
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];
            if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImage(imageUrl);
        }
    }

    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const handleDropdownToggle = (isOpen) => {
        setDropdownOpen(isOpen); 
    };

    return (
        <div className = "chat-section">
            <Row className = "mx-0">
                <Col md = {4} xxl = {3}>
                    <div className = "user-list h-100">
                        <div className = "user-list-header-mob d-md-none">
                            <Row>
                                <Col xs = {12}>
                                    <div className = "d-flex align-items-center justify-content-between">
                                        <div className = "chatbox-title">
                                            <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M4.6594 1H13.3406C13.7406 1 14.1022 1.2384 14.2597 1.60608L17 8V12C17 13.1046 16.1046 14 15 14H3C1.89543 14 1 13.1046 1 12V8L3.74025 1.60608C3.89783 1.2384 4.25937 1 4.6594 1Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M1 8H5C5.55228 8 6 8.44772 6 9V10C6 10.5523 6.44772 11 7 11H11C11.5523 11 12 10.5523 12 10V9C12 8.44772 12.4477 8 13 8H17" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span>Inbox</span>
                                        </div>
                                        <Button variant = "main" onClick={()=>setCgtShow(true)}>
                                            <i className ="bi bi-plus-lg"></i>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className = "user-list-header">
                            <Row className = "mx-0">
                                {/* <Col xs = {2} md = {2} className = "px-0 d-none">
                                    
                                    <input ref = {refOne} type = "text" value = {inputValue}  className = {isActive ? "active search" : "search"} onClick = {buttonClick} 
                                    onChange={handleInputChange}/>
                                </Col> */}
                                <Col xs = {12} className = "px-0">
                                    <div className = "d-flex align-items-center gap-2">
                                        <Button variant = "main" onClick={()=>setCgtShow(true)} className = "d-none d-md-block">
                                            <i className ="bi bi-plus-lg"></i>
                                        </Button>
                                        <input type = "text" placeholder = "Search by name or number"  className = "search" />  
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className = "chat-tab py-2 bg-white">
                            <Row className = "mx-0">
                                <Col xs = {6} className = "pe-1">
                                    <Button variant = "main" className = "active">
                                        All Conversations
                                    </Button>
                                </Col>
                                <Col xs = {6} className = "ps-1">
                                    <Button variant = "main">
                                        Assigned to me
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                       { <div className = "active-user-list">
                            <Userlist UserClass={'save-user active'} Image = {Hajira_img} handleButtonClick={handleButtonClick} Name = {'Hajira Ihsan'} Messagetitle = {'Hi, there how are you?'} 
                            ChatTag={'Event Marketing'} TagClass={'marketing'} Time = {'9:17 pm'}/>

                            <Userlist UserClass={'save-user'} Image = {Muzamil_img} handleButtonClick={handleButtonClick} Name = {' Muzamil Khan'} Messagetitle = {'Hi, there how are you?'} 
                            ChatTag={'General queries'} TagClass={'queries'} Time = {'9:17 pm'}/>

                            <Userlist UserClass={'save-user'} Image = {Rabbia_img} handleButtonClick={handleButtonClick} Name = {'Rabia Awan'} Messagetitle = {'Hi, there how are you?'} 
                            ChatTag={'Technical Support'} TagClass={'support'} Time = {'9:17 pm'}/>

                            <Userlist UserClass={'save-user unread-chat'} Image = {Hajira_img} handleButtonClick={handleButtonClick} Name = {'Anum Ihsan'} Messagetitle = {'Hi, there how are you?'} 
                            ChatTag={'Sales'} TagClass={'sales'} Time = {'Yesterday'} MessageNumber={'14'}/>

                            <Userlist UserClass={'no-user'} handleButtonClick={handleButtonClick} Name = {'+923122476076'} Messagetitle = {'Hi, there how are you? I am looking for the yesterday'} 
                            ChatTag={'Technical Support'} TagClass={'support'} Time = {'7 days ago'}/>
                        </div>}
                    </div>
                </Col>

                { showMessage && ( 
                    <Col md = {5} xxl = {6}>
                        <div className = "live-chat-sec h-100">
                            <div className = "chat-header">
                                <div className = "chat-header-sm-md d-lg-none">
                                    <div className = "assign-to-user">
                                        <ul className = "nav align-items-center justify-content-center">
                                            <li className = "nav-item">
                                                Assigned to:
                                            </li>
                                            <li className = "nav-item">
                                                <div className = "user-icon-badge">
                                                    R
                                                </div>
                                            </li>
                                            <li className = "nav-item">
                                                <div className = "user-name">
                                                    Rimsha Malik
                                                </div>
                                            </li>
                                            <li className = "nav-item">
                                                <div className = "d-flex align-items-center gap-2" onClick = {()=>setAtsShow(true)}>
                                                    <div className = "user-tag marketing">
                                                        Event Marketing
                                                    </div>
                                                    <div className = "dropdown-icon-side">
                                                        <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0 0L5 5L10 0H0Z" fill="black"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className = "chat-header-inner d-flex align-items-center justify-content-between w-100"> 
                                    <ul className = "nav user-info">
                                        <li className = "nav-item d-flex d-md-none">
                                            <div className = "back-btn" onClick={()=>{
                                                setMessage(false)
                                            }}>
                                                <i className ="bi bi-arrow-left-circle-fill"></i>
                                            </div>
                                        </li>
                                        <li className = "nav-item d-lg-none">
                                            <div className = "user-image">
                                                <img src = {Hajira_img} alt = "user-img"/>
                                                <div className = "user-status"></div>
                                            </div>
                                        </li>
                                        <li className = "nav-item d-lg-none">
                                            <div className = "user-name">
                                                Hajira Ihsan
                                            </div>
                                        </li>
                                        <li className = "nav-item assign-to-dsk">
                                            <div className = "assign-to-user px-3">
                                                <ul className = "nav align-items-center">
                                                    <li className = "nav-item">
                                                        Assigned to:
                                                    </li>
                                                    <li className = "nav-item">
                                                    <div className = "user-icon-badge">
                                                            R
                                                    </div>
                                                    </li>
                                                    <li className = "nav-item">
                                                        <div className = "user-name">
                                                            Rimsha Malik
                                                        </div>
                                                    </li>
                                                    <li className = "nav-item">
                                                        <div className = "d-flex align-items-center gap-2" onClick = {()=>setAtsShow(true)}>
                                                            <div className = "user-tag marketing">
                                                                Event Marketing
                                                            </div>
                                                            <div className = "dropdown-icon-side">
                                                                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0 0L5 5L10 0H0Z" fill="black"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>

                                    <ul className = "nav align-items-center">
                                        <li className = "nav-item">
                                            <div className = "chat-option">
                                                <Dropdown>
                                                    <Dropdown.Toggle className = "chat-o-btn">
                                                        <i className ="bi bi-three-dots-vertical"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className = "option-dropdown">
                                                        <Dropdown.Item onClick={() => setMuteShow(true)}>
                                                            Mute notifications
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => setDtShow(true)}>
                                                            Delete chat
                                                        </Dropdown.Item>
                                                        <Dropdown.Item>
                                                            Block
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className = "chat-header-tab d-md-none">
                                    <ul className = "nav align-items-center nav-fill">
                                        <li className = "nav-item">
                                            <Button variant = "navTab" className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`} onClick={() => handleTabClick('tab1')}>
                                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.2498 3.57487V9.4082H5.05817L4.08317 10.3832V3.57487H13.2498ZM14.0832 1.9082H3.24984C2.7915 1.9082 2.4165 2.2832 2.4165 2.74154V14.4082L5.74984 11.0749H14.0832C14.5415 11.0749 14.9165 10.6999 14.9165 10.2415V2.74154C14.9165 2.2832 14.5415 1.9082 14.0832 1.9082ZM18.2498 5.24154H16.5832V12.7415H5.74984V14.4082C5.74984 14.8665 6.12484 15.2415 6.58317 15.2415H15.7498L19.0832 18.5749V6.07487C19.0832 5.61654 18.7082 5.24154 18.2498 5.24154Z" fill="#B6B6B6"/>
                                                </svg> 
                                                Chat
                                            </Button>
                                        </li>
                                        <li className = "nav-item">
                                            <Button variant = "navTab" className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`} onClick={() => handleTabClick('tab2')}>
                                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.6665 14.4082H11.3332V9.4082H9.6665V14.4082ZM10.4998 7.74154C10.7359 7.74154 10.9339 7.66167 11.0936 7.50195C11.2533 7.34223 11.3332 7.14431 11.3332 6.9082C11.3332 6.67209 11.2533 6.47417 11.0936 6.31445C10.9339 6.15473 10.7359 6.07487 10.4998 6.07487C10.2637 6.07487 10.0658 6.15473 9.90609 6.31445C9.74636 6.47417 9.6665 6.67209 9.6665 6.9082C9.6665 7.14431 9.74636 7.34223 9.90609 7.50195C10.0658 7.66167 10.2637 7.74154 10.4998 7.74154ZM10.4998 18.5749C9.34706 18.5749 8.26373 18.3561 7.24984 17.9186C6.23595 17.4811 5.354 16.8874 4.604 16.1374C3.854 15.3874 3.26025 14.5054 2.82275 13.4915C2.38525 12.4776 2.1665 11.3943 2.1665 10.2415C2.1665 9.08876 2.38525 8.00542 2.82275 6.99154C3.26025 5.97765 3.854 5.0957 4.604 4.3457C5.354 3.5957 6.23595 3.00195 7.24984 2.56445C8.26373 2.12695 9.34706 1.9082 10.4998 1.9082C11.6526 1.9082 12.7359 2.12695 13.7498 2.56445C14.7637 3.00195 15.6457 3.5957 16.3957 4.3457C17.1457 5.0957 17.7394 5.97765 18.1769 6.99154C18.6144 8.00542 18.8332 9.08876 18.8332 10.2415C18.8332 11.3943 18.6144 12.4776 18.1769 13.4915C17.7394 14.5054 17.1457 15.3874 16.3957 16.1374C15.6457 16.8874 14.7637 17.4811 13.7498 17.9186C12.7359 18.3561 11.6526 18.5749 10.4998 18.5749Z" fill="#B6B6B6"/>
                                            </svg>
                                                Info
                                            </Button>
                                        </li>
                                        <li className = "nav-item">
                                            <Button variant = "navTab" className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`} onClick={() => handleTabClick('tab3')}>
                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_3376_17590)">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M16.7694 7.2337L9.17162 14.8335C9.10934 14.8978 9.00689 14.8978 8.94461 14.8335L2.23077 8.11963C2.20086 8.08943 2.18408 8.04864 2.18408 8.00613C2.18408 7.96362 2.20086 7.92283 2.23077 7.89262L9.82854 0.294857C9.86068 0.260705 9.9089 0.244634 9.9551 0.248652L16.01 0.758919C16.1667 0.772982 16.2893 0.895527 16.3033 1.05222L16.8156 7.10713C16.8196 7.15334 16.8035 7.19954 16.7694 7.2337ZM16.5424 9.00758L17.3379 9.80111C17.4002 9.86338 17.4002 9.96584 17.3379 10.0281L9.15555 18.1964C9.09327 18.2587 8.99081 18.2587 8.92854 18.1964L8.133 17.4029L3.24729 12.5252C3.21738 12.495 3.2006 12.4542 3.2006 12.4117C3.2006 12.3692 3.21738 12.3284 3.24729 12.2982L4.04282 11.5047C4.07303 11.4748 4.11382 11.458 4.15633 11.458C4.19884 11.458 4.23963 11.4748 4.26983 11.5047L9.04305 16.2658L16.3154 9.00758C16.3456 8.97767 16.3864 8.96089 16.4289 8.96089C16.4714 8.96089 16.5122 8.97767 16.5424 9.00758ZM12.1587 6.64453C13.2336 6.64453 14.105 5.77315 14.105 4.69824C14.105 3.62334 13.2336 2.75195 12.1587 2.75195C11.0838 2.75195 10.2124 3.62334 10.2124 4.69824C10.2124 5.77315 11.0838 6.64453 12.1587 6.64453Z" fill="#B6B6B6"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_3376_17590">
                                                    <rect width="18" height="18" fill="white" transform="translate(0.75 0.242188)"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                                Tags
                                            </Button>
                                        </li>
                                        <li className = "nav-item">
                                            <Button variant = "navTab" className={`nav-link ${activeTab === 'tab4' ? 'active' : ''}`} onClick={() => handleTabClick('tab4')}>
                                            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.125 15.9922H13.875V14.1172H15.75V13.3672H13.875V11.4922H13.125V13.3672H11.25V14.1172H13.125V15.9922ZM13.5 17.4922C12.4625 17.4922 11.5781 17.1266 10.8469 16.3953C10.1156 15.6641 9.75 14.7797 9.75 13.7422C9.75 12.7047 10.1156 11.8203 10.8469 11.0891C11.5781 10.3578 12.4625 9.99219 13.5 9.99219C14.5375 9.99219 15.4219 10.3578 16.1531 11.0891C16.8844 11.8203 17.25 12.7047 17.25 13.7422C17.25 14.7797 16.8844 15.6641 16.1531 16.3953C15.4219 17.1266 14.5375 17.4922 13.5 17.4922ZM5.25 6.99219H12.75V5.49219H5.25V6.99219ZM8.75625 15.9922H3.75C3.3375 15.9922 2.98437 15.8453 2.69062 15.5516C2.39687 15.2578 2.25 14.9047 2.25 14.4922V3.99219C2.25 3.57969 2.39687 3.22656 2.69062 2.93281C2.98437 2.63906 3.3375 2.49219 3.75 2.49219H14.25C14.6625 2.49219 15.0156 2.63906 15.3094 2.93281C15.6031 3.22656 15.75 3.57969 15.75 3.99219V9.01719C15.3875 8.84219 15.0219 8.71094 14.6531 8.62344C14.2844 8.53594 13.9 8.49219 13.5 8.49219C13.3625 8.49219 13.2344 8.49531 13.1156 8.50156C12.9969 8.50781 12.875 8.52344 12.75 8.54844V8.49219H5.25V9.99219H9.84375C9.61875 10.2047 9.41562 10.4359 9.23437 10.6859C9.05312 10.9359 8.89375 11.2047 8.75625 11.4922H5.25V12.9922H8.30625C8.28125 13.1172 8.26562 13.2391 8.25937 13.3578C8.25312 13.4766 8.25 13.6047 8.25 13.7422C8.25 14.1547 8.2875 14.5391 8.3625 14.8953C8.4375 15.2516 8.56875 15.6172 8.75625 15.9922Z" fill="#B6B6B6"/>
                                            </svg>
                                                Note
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>

                            <div className = "tab-content-area-desktop d-none d-lg-block position-relative">

                                <div ref={refTwo} className = "chat-message-sec">

                                    <div className = "msg-row received row mx-0">
                                        <div className = "user-img-c">
                                            <img src = {Hajira_img} alt = "user-img-c"/>
                                        </div>
                                        <div className = "msg-body">
                                            <div className = "chat-user-name">
                                                Hajira Ihsan
                                            </div>
                                            <div className = "mesg-sec received">
                                                Hi, are you available?
                                            </div>
                                            <div className = "mesg-sec received">
                                                When are we conducting the event kindly send the exact date and time. Thank you.
                                            </div>
                                            <div className = "mesg-sec received">
                                                Hi, are you available?
                                            </div>
                                            <div className = "time-area">
                                                9:18 pm
                                            </div>
                                        </div>
                                    </div>

                                    <div className = "msg-row send-msg">
                                        <div className = "user-img-c no-img">R</div>
                                        <div className = "msg-body">
                                            <div className = "chat-user-name">
                                                Rimsha Malik (Me)
                                            </div>
                                            <div className = "mesg-sec send">
                                                <img src = {Chat_img} alt = "chat-img"/>
                                                Hello everyone.
                                            </div>
                                            <div className = "mesg-sec send">
                                                yes, available.
                                            </div>
                                            <div className = "mesg-sec send">
                                                Sure, will send you in a while.
                                            </div>

                                            {/* Change classname ppt, pdf, doc, xls to change the file icon in message */}
                                            <div className = "mesg-sec send doc_msg ppt">
                                                <ul className = "nav align-item-center gap-3">
                                                    <li className = "nav-item">
                                                        <img className = "doc-icon doc_file-icon" src = {Doc_file} alt = "doc-file_icon"/>
                                                        <img className = "pdf-icon doc_file-icon" src = {Pdf_file} alt = "ppt-file_icon"/>
                                                        <img className = "ppt-icon doc_file-icon" src = {Ppt_file} alt = "pdf-file_icon"/>
                                                        <img className = "xls-icon doc_file-icon" src = {Xls_file} alt = "xls-file_icon"/>
                                                    </li>
                                                    <li className = "nav-item">
                                                        <div className = "file-content">
                                                            <div className = "file-name">MWAN Document-1.docx</div>
                                                            <div className = "file-size">
                                                                8 KB . docx
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                            {/* Documents file message with message text */}
                                            <div className = "mesg-sec send doc_msg ppt doc_with_msg">
                                                <ul className = "nav align-item-center gap-3">
                                                    <li className = "nav-item">
                                                        <img className = "doc-icon doc_file-icon" src = {Doc_file} alt = "doc-file_icon"/>
                                                        <img className = "pdf-icon doc_file-icon" src = {Pdf_file} alt = "ppt-file_icon"/>
                                                        <img className = "ppt-icon doc_file-icon" src = {Ppt_file} alt = "pdf-file_icon"/>
                                                        <img className = "xls-icon doc_file-icon" src = {Xls_file} alt = "xls-file_icon"/>
                                                    </li>
                                                    <li className = "nav-item">
                                                        <div className = "file-content">
                                                            <div className = "file-name">MWAN Document-1.docx</div>
                                                            <div className = "file-size">
                                                                8 KB . docx
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className = "doc_msg_text">
                                                    Hi, This is the new document.
                                                </div>
                                            </div>

                                            <div className = "time-area">
                                                <div className = "chat-send">
                                                    <img src = {Chat_tick} alt = "chat-tick"/>
                                                </div>
                                                <div className = "chat-received">
                                                    <img src = {Chat_seen} alt = "chat-seen-icons"/>
                                                </div>
                                                <div className = "chat-dely">
                                                    <img src = {Chat_delay} alt = "chat-clock_icon"/>
                                                </div>
                                                <span>9:26 pm</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className = "msg-row send-msg">
                                        <div className = "user-img-c no-img">R</div>
                                        <div className = "chat-user-name">
                                            Rimsha Malik (Me)
                                        </div>
                                        <div className = "msg-body">
                                            <div className = "mesg-sec send share-contact">
                                                <ul className = "nav">
                                                    <li className = "nav-item">
                                                        <div className = "user-img">
                                                            <img src = {Hajira_img} alt = "user-img"/>
                                                        </div>
                                                    </li>
                                                    <li className = "nav-item">
                                                        <div className = "share-user-name">
                                                            Hajira
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className = "share-action">
                                                    <ul className = "nav nav-fill p-0">
                                                        <li className = "nav-item">
                                                            <Button variant = "share-contact">
                                                                Message
                                                            </Button>
                                                        </li>
                                                        <li className = "nav-item">
                                                            <Button variant = "share-contact">
                                                                Save Contact
                                                            </Button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className = "time-area">
                                                9:18 pm
                                            </div>
                                        </div>
                                    </div>

                                    <div className = "msg-row send-msg">
                                        <div className = "user-img-c no-img">R</div>
                                        <div className = "msg-body">
                                            <div className = "chat-user-name">
                                                Rimsha Malik (Me)
                                            </div>
                                            <div className = "mesg-sec send">
                                                <img src = {Chat_img} alt = "chat-img"/>
                                                Hello everyone.
                                            </div>
                                            <div className = "mesg-sec send">
                                                yes, available.
                                            </div>
                                            <div className = "mesg-sec send">
                                                Sure, will send you in a while.
                                            </div>

                                            {/* Change classname ppt, pdf, doc, xls to change the file icon in message */}
                                            <div className = "mesg-sec send doc_msg ppt">
                                                <ul className = "nav align-item-center gap-3">
                                                    <li className = "nav-item">
                                                        <img className = "doc-icon doc_file-icon" src = {Doc_file} alt = "doc-file_icon"/>
                                                        <img className = "pdf-icon doc_file-icon" src = {Pdf_file} alt = "ppt-file_icon"/>
                                                        <img className = "ppt-icon doc_file-icon" src = {Ppt_file} alt = "pdf-file_icon"/>
                                                        <img className = "xls-icon doc_file-icon" src = {Xls_file} alt = "xls-file_icon"/>
                                                    </li>
                                                    <li className = "nav-item">
                                                        <div className = "file-content">
                                                            <div className = "file-name">MWAN Document-1.docx</div>
                                                            <div className = "file-size">
                                                                8 KB . docx
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>

                                    <div className = "msg-row send-msg msg-option" style={{ zIndex: isDropdownOpen ? 999 : 'auto' }}> 
                                        <div className = "mesg-sec send">
                                            yes, available.
                                            <div className = "chat-option">
                                                <Dropdown onToggle={handleDropdownToggle}>
                                                    <Dropdown.Toggle className = "chat-o-btn">
                                                        <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.88105 1.30859L5.50009 5.68955L1.11914 1.30859" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className = "option-dropdown">
                                                        <Dropdown.Item onClick = {showReply}>
                                                            Reply
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => setEditShow(true)}>
                                                            Edit
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => setDtmShow(true)}>
                                                            Delete
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div className = "msg-row send-msg msg-option view-reply">
                                        <div className = "mesg-sec send">
                                            <div className = "reply-msg-view">
                                                Whether you're looking  to earn or expand your project's impact, our platform offers a diverse range of tasks and micro jobs tailored to fit your needs.
                                            </div>
                                            <div className = "reply-ans">
                                                Got your message.
                                            </div>
                                            <div className = "chat-option">
                                                <Dropdown>
                                                    <Dropdown.Toggle className = "chat-o-btn">
                                                        <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.88105 1.30859L5.50009 5.68955L1.11914 1.30859" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className = "option-dropdown">
                                                        <Dropdown.Item onClick = {showReply}>
                                                            Reply
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => setEditShow(true)}>
                                                            Edit
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => setDtmShow(true)}>
                                                            Delete
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div className = "msg-row send-msg">
                                        <div className = "user-img-c no-img">R</div>
                                        <div className = "msg-body">
                                            <div className = "chat-user-name">
                                                Rimsha Malik (Me)
                                            </div>
                                            <div className = "mesg-sec send">
                                                <div className="message-preview-sec">
                                                    <div className="message-prreview-inner">
                                                        <div className="start-heading">Hey, [Eda]</div>
                                                        <div className="message-details">
                                                            We are excited to inform you about our upcoming event, [GCC eHEALTH WORKFORCE DEVELOPMENT CONFERENCE]! This is a great opportunity for you to connect with industry experts, learn about the latest trends, and expand your network.<br/><br/>
                                                            We are offering an exclusive discount code for our valued participants. Use the code [GCC20] to get a special discount on your registration.
                                                        </div>
                                                        <div className="regard-text">Best Regards</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className = "msg-row received row mx-0">
                                        {/* Documents file message with message text */}
                                        <div className = "mesg-sec received doc_msg ppt doc_with_msg">
                                            <ul className = "nav align-item-center gap-3">
                                                <li className = "nav-item">
                                                    <img className = "doc-icon doc_file-icon" src = {Doc_file} alt = "doc-file_icon"/>
                                                    <img className = "pdf-icon doc_file-icon" src = {Pdf_file} alt = "ppt-file_icon"/>
                                                    <img className = "ppt-icon doc_file-icon" src = {Ppt_file} alt = "pdf-file_icon"/>
                                                    <img className = "xls-icon doc_file-icon" src = {Xls_file} alt = "xls-file_icon"/>
                                                </li>
                                                <li className = "nav-item">
                                                    <div className = "file-content">
                                                        <div className = "file-name">MWAN Document-1.docx</div>
                                                        <div className = "file-size">
                                                            8 KB . docx
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className = "doc_msg_text">
                                                Hi, This is the new document.
                                            </div>
                                        </div>

                                        <div className = "time-area">
                                            <span>9:26 pm</span>
                                        </div>
                                        
                                        <div className = "user-img-c">
                                            <img src = {Hajira_img} alt = "user-img-c"/>
                                        </div>
                                        <div className = "msg-body">
                                            <div className = "chat-user-name">
                                                Hajira Ihsan
                                            </div>
                                            <div className = "mesg-sec received">
                                                When are we conducting the event kindly send the exact date and time. Thank you.
                                            </div>
                                            <div className = "mesg-sec received">
                                                Hi, are you available?
                                            </div>
                                            <div className = "mesg-sec received">
                                                When are we conducting the event kindly send the exact date and time. Thank you.
                                            </div>
                                            <div className = "time-area">
                                                9:18 pm
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className = "write-msg-sec">

                                    <Row className = {`reply-row ${replyshow ? 'show' : ''}`}>
                                        <Col sm = {12}>
                                            <div className = "reply-input">
                                                <div className = "ri-header">
                                                    You
                                                </div>
                                                <div className = "ri-msg">
                                                    Hello Hajira
                                                </div>
                                            </div>
                                            <button className = "close-reply" onClick = {hideReply}>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.94004 8.00012L13.14 3.80679C13.2656 3.68125 13.3361 3.51099 13.3361 3.33346C13.3361 3.15592 13.2656 2.98566 13.14 2.86012C13.0145 2.73459 12.8442 2.66406
                                                    12.6667 2.66406C12.4892 2.66406 12.3189 2.73459 12.1934 2.86012L8.00004 7.06012L3.80671 2.86012C3.68117 2.73459 3.51091 2.66406 3.33337 2.66406C3.15584 2.66406 2.98558 2.73459 2.86004 2.86012C2.7345 2.98566 2.66398 3.15592 2.66398 3.33346C2.66398 3.51099 2.7345 3.68125 2.86004 3.80679L7.06004 8.00012L2.86004 12.1935C2.79756 12.2554 2.74796 12.3292 2.71411 12.4104C2.68027 12.4916 2.66284 12.5788 2.66284 12.6668C2.66284 12.7548 2.68027 12.8419 2.71411 12.9232C2.74796 13.0044 2.79756 13.0781 2.86004 13.1401C2.92202 13.2026 2.99575 13.2522 3.07699 13.2861C3.15823 13.3199 3.24537 13.3373 3.33337 13.3373C3.42138 13.3373 3.50852 13.3199 3.58976 13.2861C3.671 13.2522 3.74473 13.2026 3.80671 13.1401L8.00004 8.94012L12.1934 13.1401C12.2554 13.2026 12.3291 13.2522 12.4103 13.2861C12.4916 13.3199 12.5787 13.3373 12.6667 13.3373C12.7547 13.3373 12.8419 13.3199 12.9231 13.2861C13.0043 13.2522 13.0781 13.2026 13.14 13.1401C13.2025 13.0781 13.2521 13.0044 13.286 12.9232C13.3198 12.8419 13.3372 12.7548 13.3372 12.6668C13.3372 12.5788 13.3198 12.4916 13.286 12.4104C13.2521 12.3292 13.2025 12.2554 13.14 12.1935L8.94004 8.00012Z" fill="black">
                                                    </path>
                                                </svg>
                                            </button>
                                        </Col>
                                    </Row>

                                    <div className = "row mx-0 position-relative">
                                        <Col sm = {12} className = "px-0">
                                            <div className = "attached-file">
                                                <Dropdown>
                                                    <Dropdown.Toggle className = "attached-btn">
                                                        <i className="bi bi-paperclip"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className = "attached-option">
                                                        <Dropdown.Item>
                                                            <ul className = "nav align-items-center">
                                                                <li className = "nav-item">
                                                                    <div className = "link-icon">
                                                                        <i className="bi bi-camera"></i>
                                                                    </div>
                                                                </li>
                                                                <li className = "nav-item">
                                                                    Capture
                                                                </li>
                                                            </ul>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick = {showDoc}>
                                                            <ul className = "nav align-items-center">
                                                                <li className = "nav-item">
                                                                    <div className = "link-icon">
                                                                        <i className="bi bi-file-earmark"></i>
                                                                    </div>
                                                                </li>
                                                                <li className = "nav-item">
                                                                    Document
                                                                </li>
                                                            </ul>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item>
                                                            <ul className = "nav align-items-center">
                                                                <li className = "nav-item">
                                                                    <div className = "link-icon">
                                                                        <i className="bi bi-person"></i>
                                                                    </div>
                                                                </li>
                                                                <li className = "nav-item">
                                                                    Contact
                                                                </li>
                                                            </ul>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={showImage}>
                                                            <ul className = "nav align-items-center">
                                                                <li className = "nav-item">
                                                                    <div className = "link-icon">
                                                                        <i className="bi bi-image"></i>
                                                                    </div>
                                                                </li>
                                                                <li className = "nav-item">
                                                                    Gallery
                                                                </li>
                                                            </ul>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>setSelectTemplateShow(true)}>
                                                            <ul className = "nav align-items-center">
                                                                <li className = "nav-item">
                                                                    <div className = "link-icon">
                                                                        <i className="bi bi-envelope-paper"></i>
                                                                    </div>
                                                                </li>
                                                                <li className = "nav-item">
                                                                    Send Template
                                                                </li>
                                                            </ul>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className = "text-input">
                                                <input type = "text"/>
                                            </div>
                                            <div className = "command-sec">
                                                <ul className = "nav align-items-center">
                                                    <li className = "nav-item">
                                                        <Button variant = "mic">
                                                            <svg width="17" height="23" viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.1377 4.52188C12.1377 2.5768 10.6732 1 8.86648 1C7.05981 1 5.59521 2.5768 5.59521 4.52188V11.0625C5.59521 13.0076 7.05981 14.5844 8.86648 14.5844C10.6732 14.5844 12.1377 13.0076 12.1377 11.0625V4.52188Z" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
                                                                <path d="M1.85645 10.5586C1.85645 14.7265 4.99499 18.1055 8.8663 18.1055M8.8663 18.1055C12.7376 18.1055 15.8762 14.7265 15.8762 10.5586M8.8663 18.1055V21.1242" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                        </Button>
                                                    </li>
                                                    <li className = "nav-item">
                                                        <Button variant = "send">
                                                            <img src = {Send_icon} alt = "icon"/>
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </div>

                                </div>
                                
                                <div className = {`doc-adding ${docshow ? 'show' : ''}`}>
                                    <div className = "doc-header">
                                        <button className = "close-doc" onClick = {hideDoc}>
                                            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M23.3926 11.6074L11.6075 23.3925" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M23.3926 23.3926L11.6075 11.6075" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className = "doc-body">
                                        <div className = "doc-img">
                                            {/* <img src = {Doc_file} alt = "doc-img"/> */}
                                            {renderIcon()}
                                        </div>
                                        <div className = "doc-description">
                                            {file ? (<>
                                                <div className = "doc-name">
                                                    {/* MWAN Document-1.docx */}
                                                        {file.name}
                                                </div>
                                                <div className = "doc-size">
                                                    {/* 8 KB - DOC */}
                                                        {file.size} KB
                                                </div>
                                            </>) : (
                                                <div>No file uploaded</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className = "doc-msg">
                                        <input className = "doc-details" type = "text" placeholder = "Type a message here...."/>
                                    </div>
                                    <div className = "doc-list">

                                        <ul className = "nav align-items-center justify-content-center">
                                            <li className = "nav-item">
                                                <div className = "doc-item">
                                                    <div className = "doc-img-icon">
                                                        <img src = {Doc_file} alt = "doc-icon"/>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className = "nav-item">
                                                <div className = "doc-item upload-doc" onChange={handleFileChange}>
                                                    <input type = "file"/>
                                                    <div className = "doc-img-icon">
                                                        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.11914 15.5H22.881" stroke="#4B4A4A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M15.5005 8.11914V22.881" stroke="#4B4A4A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <Button variant = "send">
                                            <img src = {Send_icon} alt = "icon"/>
                                        </Button>

                                    </div>
                                </div>

                                <div className = {`img-adding ${imageshow ? 'show' : ''}`}>
                                    <div className = "img-header">
                                        <button className = "close-doc" onClick = {hideImage}>
                                            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M23.3926 11.6074L11.6075 23.3925" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M23.3926 23.3926L11.6075 11.6075" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className = "img-body">
                                        {image ? (
                                            <img src={image} alt="Uploaded" className = "added-img"/>
                                            ) : (
                                        <p>No image selected</p>
                                        )}
                                    </div>
                                    <div className = "img-msg">
                                        <input type = "text" className = "img-msg-details" placeholder = "Type a message here...."/>
                                    </div>
                                    <div className = "img-list">
                                        <ul className = "nav align-items-center justify-content-center">
                                            {image && (<li className = "nav-item">
                                                <div className = "img-item">
                                                    <img src = {image} alt = "Thumpnil"/>
                                                    <div className = "delete-btn">
                                                        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.75 25.375C8.0625 25.375 7.47417 25.1386 6.985 24.6657C6.495 24.192 6.25 23.6229 6.25 22.9583V7.25H5V4.83333H11.25V3.625H18.75V4.83333H25V7.25H23.75V22.9583C23.75 23.6229 23.5054 24.192 23.0163 24.6657C22.5263 25.1386 21.9375 25.375 21.25 25.375H8.75ZM21.25 7.25H8.75V22.9583H21.25V7.25ZM11.25 20.5417H13.75V9.66667H11.25V20.5417ZM16.25 20.5417H18.75V9.66667H16.25V20.5417Z" fill="white"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </li>)}
                                            <li className = "nav-item">
                                                <div className = "img-item upload-img" onClick={handleImageUpload}>
                                                    <input type = "file" accept="image/*" ref = {ImageInputRef} onChange = {handleImageChange}/>
                                                    <div className = "doc-img-icon">
                                                        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.11914 15.5H22.881" stroke="#4B4A4A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M15.5005 8.11914V22.881" stroke="#4B4A4A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </li>
                                            <Button variant = "send">
                                                <img src = {Send_icon} alt = "icon"/>
                                            </Button>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <div className = "tab-content-area-mob d-lg-none position-relative">

                                <div className={`tab-pane fade ${activeTab === 'tab1' ? 'show active' : ''}`}>
                                    <div ref={refTwo} className = "chat-message-sec">

                                        <div className = "msg-row received row mx-0">
                                            <div className = "user-img-c">
                                                <img src = {Hajira_img} alt = "user-img-c"/>
                                            </div>
                                            <div className = "msg-body">
                                                <div className = "chat-user-name">
                                                    Hajira Ihsan
                                                </div>
                                                <div className = "mesg-sec received">
                                                    Hi, are you available?
                                                </div>
                                                <div className = "mesg-sec received">
                                                    When are we conducting the event kindly send the exact date and time. Thank you.
                                                </div>
                                                <div className = "mesg-sec received">
                                                    Hi, are you available?
                                                </div>
                                                <div className = "time-area">
                                                    9:18 pm
                                                </div>
                                            </div>
                                        </div>

                                        <div className = "msg-row send-msg">
                                            <div className = "user-img-c no-img">R</div>
                                            <div className = "msg-body">
                                                <div className = "chat-user-name">
                                                    Rimsha Malik (Me)
                                                </div>
                                                <div className = "mesg-sec send">
                                                    <img src = {Chat_img} alt = "chat-img"/>
                                                    Hello everyone.
                                                </div>
                                                <div className = "mesg-sec send">
                                                    yes, available.
                                                </div>
                                                <div className = "mesg-sec send">
                                                    Sure, will send you in a while.
                                                </div>
                                                <div className = "time-area">
                                                    <div className = "chat-send">
                                                        <img src = {Chat_tick} alt = "chat-tick"/>
                                                    </div>
                                                    <div className = "chat-received">
                                                        <img src = {Chat_seen} alt = "chat-seen-icons"/>
                                                    </div>
                                                    <div className = "chat-dely">
                                                        <img src = {Chat_delay} alt = "chat-clock_icon"/>
                                                    </div>
                                                    <span>9:26 pm</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className = "msg-row send-msg">
                                            <div className = "user-img-c no-img">R</div>
                                            <div className = "msg-body">
                                                <div className = "chat-user-name">
                                                    Rimsha Malik (Me)
                                                </div>
                                                <div className = "mesg-sec send">
                                                    <img src = {Chat_img} alt = "chat-img"/>
                                                    Hello everyone.
                                                </div>
                                                <div className = "mesg-sec send">
                                                    yes, available.
                                                </div>
                                                <div className = "mesg-sec send">
                                                    Sure, will send you in a while.
                                                </div>

                                                {/* Change classname ppt, pdf, doc, xls to change the file icon in message */}
                                                <div className = "mesg-sec send doc_msg ppt">
                                                    <ul className = "nav align-item-center gap-3">
                                                        <li className = "nav-item">
                                                            <img className = "doc-icon doc_file-icon" src = {Doc_file} alt = "doc-file_icon"/>
                                                            <img className = "pdf-icon doc_file-icon" src = {Pdf_file} alt = "ppt-file_icon"/>
                                                            <img className = "ppt-icon doc_file-icon" src = {Ppt_file} alt = "pdf-file_icon"/>
                                                            <img className = "xls-icon doc_file-icon" src = {Xls_file} alt = "xls-file_icon"/>
                                                        </li>
                                                        <li className = "nav-item">
                                                            <div className = "file-content">
                                                                <div className = "file-name">MWAN Document-1.docx</div>
                                                                <div className = "file-size">
                                                                    8 KB . docx
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>

                                        <div className = "msg-row send-msg msg-option">
                                            <div className = "mesg-sec send">
                                                yes, available.
                                                <div className = "chat-option">
                                                    <Dropdown>
                                                        <Dropdown.Toggle className = "chat-o-btn">
                                                            <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M9.88105 1.30859L5.50009 5.68955L1.11914 1.30859" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className = "option-dropdown">
                                                            <Dropdown.Item onClick = {showReply}>
                                                                Reply
                                                            </Dropdown.Item>
                                                            <Dropdown.Item onClick = {()=>setEditShow(true)}>
                                                                Edit
                                                            </Dropdown.Item>
                                                            <Dropdown.Item onClick={() => setDtmShow(true)}>
                                                                Delete
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>

                                        <div className = "msg-row send-msg">
                                            <div className = "user-img-c no-img">R</div>
                                            <div className = "msg-body">
                                                <div className = "chat-user-name">
                                                    Rimsha Malik (Me)
                                                </div>
                                                <div className = "mesg-sec send">
                                                    <div className="message-preview-sec">
                                                        <div className="message-prreview-inner">
                                                            <div className="start-heading">Hey, [Eda]</div>
                                                            <div className="message-details">
                                                                We are excited to inform you about our upcoming event, [GCC eHEALTH WORKFORCE DEVELOPMENT CONFERENCE]! This is a great opportunity for you to connect with industry experts, learn about the latest trends, and expand your network.<br/><br/>
                                                                We are offering an exclusive discount code for our valued participants. Use the code [GCC20] to get a special discount on your registration.
                                                            </div>
                                                            <div className="regard-text">Best Regards</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className = "msg-row received row mx-0">
                                            <div className = "user-img-c">
                                                <img src = {Hajira_img} alt = "user-img-c"/>
                                            </div>
                                            <div className = "msg-body">
                                                <div className = "chat-user-name">
                                                    Hajira Ihsan
                                                </div>
                                                <div className = "mesg-sec received">
                                                    When are we conducting the event kindly send the exact date and time. Thank you.
                                                </div>
                                                <div className = "mesg-sec received">
                                                    Hi, are you available?
                                                </div>
                                                <div className = "mesg-sec received">
                                                    When are we conducting the event kindly send the exact date and time. Thank you.
                                                </div>
                                                <div className = "mesg-sec received doc_msg">
                                                    <ul className = "nav align-item-center gap-2">
                                                        <li className = "nav-item">
                                                            <img src = {Doc_file} alt = "doc-file_icon"/>
                                                        </li>
                                                        <li className = "nav-item">
                                                            <div className = "file-content">
                                                                <div className = "file-name">MWAN Document-1.docx</div>
                                                                <div className = "file-size">
                                                                    8 KB . docx
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className = "time-area">
                                                    9:18 pm
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className = "write-msg-sec">
                                        <Row className = {`reply-row ${replyshow ? 'show' : ''}`}>
                                            <Col sm = {12}>
                                                <div className = "reply-input">
                                                    <div className = "ri-header">
                                                        You
                                                    </div>
                                                    <div className = "ri-msg">
                                                        Hello Hajira
                                                    </div>
                                                </div>
                                                <button className = "close-reply" onClick = {hideReply}>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.94004 8.00012L13.14 3.80679C13.2656 3.68125 13.3361 3.51099 13.3361 3.33346C13.3361 3.15592 13.2656 2.98566 13.14 2.86012C13.0145 2.73459 12.8442 2.66406
                                                        12.6667 2.66406C12.4892 2.66406 12.3189 2.73459 12.1934 2.86012L8.00004 7.06012L3.80671 2.86012C3.68117 2.73459 3.51091 2.66406 3.33337 2.66406C3.15584 2.66406 2.98558 2.73459 2.86004 2.86012C2.7345 2.98566 2.66398 3.15592 2.66398 3.33346C2.66398 3.51099 2.7345 3.68125 2.86004 3.80679L7.06004 8.00012L2.86004 12.1935C2.79756 12.2554 2.74796 12.3292 2.71411 12.4104C2.68027 12.4916 2.66284 12.5788 2.66284 12.6668C2.66284 12.7548 2.68027 12.8419 2.71411 12.9232C2.74796 13.0044 2.79756 13.0781 2.86004 13.1401C2.92202 13.2026 2.99575 13.2522 3.07699 13.2861C3.15823 13.3199 3.24537 13.3373 3.33337 13.3373C3.42138 13.3373 3.50852 13.3199 3.58976 13.2861C3.671 13.2522 3.74473 13.2026 3.80671 13.1401L8.00004 8.94012L12.1934 13.1401C12.2554 13.2026 12.3291 13.2522 12.4103 13.2861C12.4916 13.3199 12.5787 13.3373 12.6667 13.3373C12.7547 13.3373 12.8419 13.3199 12.9231 13.2861C13.0043 13.2522 13.0781 13.2026 13.14 13.1401C13.2025 13.0781 13.2521 13.0044 13.286 12.9232C13.3198 12.8419 13.3372 12.7548 13.3372 12.6668C13.3372 12.5788 13.3198 12.4916 13.286 12.4104C13.2521 12.3292 13.2025 12.2554 13.14 12.1935L8.94004 8.00012Z" fill="black">
                                                        </path>
                                                    </svg>
                                                </button>
                                            </Col>
                                        </Row>
                                        <div className = "row mx-0 position-relative">
                                            <Col sm = {12} className = "px-0">
                                                <div className = "attached-file">
                                                    <Dropdown>
                                                        <Dropdown.Toggle className = "attached-btn">
                                                            <i className="bi bi-paperclip"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className = "attached-option">
                                                            <Dropdown.Item>
                                                                <ul className = "nav align-items-center">
                                                                    <li className = "nav-item">
                                                                        <div className = "link-icon">
                                                                            <i className="bi bi-camera"></i>
                                                                        </div>
                                                                    </li>
                                                                    <li className = "nav-item">
                                                                        Capture
                                                                    </li>
                                                                </ul>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item onClick = {showDoc}>
                                                                <ul className = "nav align-items-center">
                                                                    <li className = "nav-item">
                                                                        <div className = "link-icon">
                                                                            <i className="bi bi-file-earmark"></i>
                                                                        </div>
                                                                    </li>
                                                                    <li className = "nav-item">
                                                                        Document
                                                                    </li>
                                                                </ul>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item>
                                                                <ul className = "nav align-items-center">
                                                                    <li className = "nav-item">
                                                                        <div className = "link-icon">
                                                                            <i className="bi bi-person"></i>
                                                                        </div>
                                                                    </li>
                                                                    <li className = "nav-item">
                                                                        Contact
                                                                    </li>
                                                                </ul>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item onClick = {showImage}>
                                                                <ul className = "nav align-items-center">
                                                                    <li className = "nav-item">
                                                                        <div className = "link-icon">
                                                                            <i className="bi bi-image"></i>
                                                                        </div>
                                                                    </li>
                                                                    <li className = "nav-item">
                                                                        Gallery
                                                                    </li>
                                                                </ul>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item onClick = {()=>setMobSelectTemplateShow(true)}>
                                                                <ul className = "nav align-items-center">
                                                                    <li className = "nav-item">
                                                                        <div className = "link-icon">
                                                                            <i className="bi bi-envelope-paper"></i>
                                                                        </div>
                                                                    </li>
                                                                    <li className = "nav-item">
                                                                        Send Template
                                                                    </li>
                                                                </ul>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                                <div className = "text-input">
                                                    <input type = "text"/>
                                                </div>
                                                <div className = "command-sec">
                                                    <ul className = "nav align-items-center">
                                                        <li className = "nav-item">
                                                            <Button variant = "mic">
                                                                <svg width="17" height="23" viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12.1377 4.52188C12.1377 2.5768 10.6732 1 8.86648 1C7.05981 1 5.59521 2.5768 5.59521 4.52188V11.0625C5.59521 13.0076 7.05981 14.5844 8.86648 14.5844C10.6732 14.5844 12.1377 13.0076 12.1377 11.0625V4.52188Z" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
                                                                    <path d="M1.85645 10.5586C1.85645 14.7265 4.99499 18.1055 8.8663 18.1055M8.8663 18.1055C12.7376 18.1055 15.8762 14.7265 15.8762 10.5586M8.8663 18.1055V21.1242" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg>
                                                            </Button>
                                                        </li>
                                                        <li className = "nav-item">
                                                            <Button variant = "send">
                                                                <img src = {Send_icon} alt = "icon"/>
                                                            </Button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                        </div>
                                    </div>

                                    <div className = {`doc-adding ${docshow ? 'show' : ''}`}>
                                        <div className = "doc-header">
                                            <button className = "close-doc" onClick = {hideDoc}>
                                                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.3926 11.6074L11.6075 23.3925" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M23.3926 23.3926L11.6075 11.6075" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className = "doc-body">
                                            <div className = "doc-img">
                                                {/* <img src = {Doc_file} alt = "doc-img"/> */}
                                                {renderIcon()}
                                            </div>
                                            <div className = "doc-description">
                                                {file ? (<>
                                                    <div className = "doc-name">
                                                        {/* MWAN Document-1.docx */}
                                                            {file.name}
                                                    </div>
                                                    <div className = "doc-size">
                                                        {/* 8 KB - DOC */}
                                                            {file.size} KB
                                                    </div>
                                                </>) : (
                                                    <div>No file uploaded</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className = "doc-msg">
                                            <input className = "doc-details" type = "text" placeholder = "Type a message here...."/>
                                            <Button variant = "send">
                                                <img src = {Send_icon} alt = "icon"/>
                                            </Button>
                                        </div>
                                        <div className = "doc-list">

                                            <ul className = "nav align-items-center justify-content-center">
                                                <li className = "nav-item">
                                                    <div className = "doc-item upload-doc" onChange={handleFileChange}>
                                                        <input type = "file"/>
                                                        <div className = "doc-img-icon">
                                                            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.11914 15.5H22.881" stroke="#4B4A4A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path d="M15.5005 8.11914V22.881" stroke="#4B4A4A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>

                                    <div className = {`img-adding ${imageshow ? 'show' : ''}`}>
                                        <div className = "img-header">
                                            <button className = "close-doc" onClick = {hideImage}>
                                                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.3926 11.6074L11.6075 23.3925" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M23.3926 23.3926L11.6075 11.6075" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className = "img-body">
                                            {image ? (
                                                <img src={image} alt="Uploaded" className = "added-img"/>
                                                ) : (
                                            <p>No image selected</p>
                                            )}
                                        </div>

                                        <div className = "img-list">
                                            <ul className = "nav align-items-center justify-content-center">
                                                {image && (<li className = "nav-item">
                                                    <div className = "img-item">
                                                        <img src = {image} alt = "Thumpnil"/>
                                                        <div className = "delete-btn">
                                                            <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.75 25.375C8.0625 25.375 7.47417 25.1386 6.985 24.6657C6.495 24.192 6.25 23.6229 6.25 22.9583V7.25H5V4.83333H11.25V3.625H18.75V4.83333H25V7.25H23.75V22.9583C23.75 23.6229 23.5054 24.192 23.0163 24.6657C22.5263 25.1386 21.9375 25.375 21.25 25.375H8.75ZM21.25 7.25H8.75V22.9583H21.25V7.25ZM11.25 20.5417H13.75V9.66667H11.25V20.5417ZM16.25 20.5417H18.75V9.66667H16.25V20.5417Z" fill="white"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </li>)}
                                                <li className = "nav-item">
                                                    <div className = "img-item upload-img" onClick={handleImageUpload}>
                                                        <input type = "file" accept="image/*" ref = {ImageInputRef} onChange = {handleImageChange}/>
                                                        <div className = "doc-img-icon">
                                                            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.11914 15.5H22.881" stroke="#4B4A4A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path d="M15.5005 8.11914V22.881" stroke="#4B4A4A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        
                                        <div className = "img-msg">
                                            <input type = "text" className = "img-msg-details" placeholder = "Type a message here...."/>
                                            <Button variant = "send">
                                                <img src = {Send_icon} alt = "icon"/>
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                                
                                <div className={`tab-pane fade ${activeTab === 'tab2' ? 'show active' : ''}`}>
                                    <UserBasicInfo/>
                                </div>
                                <div className={`tab-pane fade ${activeTab === 'tab3' ? 'show active' : ''}`}>
                                    <UserTags/>
                                </div>
                                <div className={`tab-pane fade ${activeTab === 'tab4' ? 'show active' : ''}`}>
                                    <UserNotes/>
                                </div>

                            </div>

                        </div>
                    </Col>
                )}

                <Col md = {3} xxl = {3} className = "d-none d-md-block">
                    <div className = "user-info-data h-100 d-none">
                        <div className = "user-info-header">
                            <div className = "user-name">Hajira Ihsan</div>
                            <div className = "online-status">Available <span></span></div>
                        </div>
                        <div className = "user-personal-info">
                            <div className = "info-row">
                                <div className = "title">Basic Information</div>
                                <div className = "description">Phone number: 03025676625</div>
                            </div>
                            <div className = "info-row">
                                <div className = "title">About</div>
                                <div className = "description">Company: MWAN MOBILE</div>
                                <div className = "description">Designation: UX/UI Designer</div>
                            </div>
                        </div>
                    </div>
                    <div className = "chat-info-side h-100">
                        <ChatAccordion/>
                    </div>
                </Col>
            </Row>

            <Modal show={cgtshow} onHide={() => setCgtShow(false)} className = "whatsapp-modal modal-g-contact modal-select-contact" centered>
                <Modal.Header className = "p-0" closeButton>
                    <Modal.Title>
                        New Message
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Control type = "text" placeholder = "Search Contact"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className = "mx-0">
                        <div className = "col-12 contact-col">
                            <Row className = "mb-2 contact-group">
                                <Col sm = {12}>
                                    <div className = "user-name">
                                        <Groupcontact Image = {Hajira_img} name = {'Ava Brown'}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className = "mb-2 contact-group">
                                <Col sm = {12}>
                                    <div className = "user-name">
                                        <Groupcontact Image = {Muzamil_img} name = {'Ava Brown'}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className = "mb-2 contact-group">
                                <Col sm = {12}>
                                    <div className = "user-name">
                                        <Groupcontact Image = {Rabbia_img} name = {'Ava Brown'}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className = "mb-2 contact-group">
                                <Col sm = {12}>
                                    <div className = "user-name">
                                        <Groupcontact Image = {Hajira_img} name = {'Ava Brown'}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className = "mb-2 contact-group">
                                <Col sm = {12}>
                                    <div className = "user-name">
                                        <Groupcontact Image = {Rabbia_img} name = {'Ava Brown'}/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <div className = "attention-msg">
                        Couldn’t find contact in the list?
                    </div>
                    <Button  variant = "main-default" onClick={() => {setCgtShow(false) 
                        setCtShow(true)}}>
                        Add New Contact
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={ctshow} onHide={() => setCtShow(false)} className = "whatsapp-modal modal-contact" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title>Add Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm = {6}>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type = "text" placeholder = "Enter name"/>
                            </Form.Group>
                        </Col>
                        <Col sm = {6}>
                            <Form.Group>
                                <Form.Label>Phone No</Form.Label>
                                <Form.Control type = "text" placeholder = "Ex: 923025676625"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type = "email" placeholder = "Enter email"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Button className = "import-cvs">
                                Import Contacts From CSV File
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button className = "btn-main-default" onClick={()=>setCtShow(false)}>
                        Cancel
                    </Button>
                    <Button  className = "btn-main" onClick={()=>setCtShow(false)}>
                        Add Contact
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={muteshow} onHide={() => setMuteShow(false)} className = "whatsapp-modal modal-contact" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title>Mute “Hajira” for ...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs = {12} className = "mb-3">
                            <div className = "radio-row d-flex align-items-center">
                                <input name = "radio" type = "radio" className = "form-check-input mt-0" id = "8hours"/>
                                <Form.Label htmlFor = "8hours" className = "mb-0 ms-2">8 hours</Form.Label>
                            </div>
                        </Col>
                        <Col xs = {12} className = "mb-3">
                            <div className = "radio-row d-flex align-items-center">
                                <input name = "radio" type = "radio" className = "form-check-input mt-0" id = "1week"/>
                                <Form.Label htmlFor = "1week" className = "mb-0 ms-2">1 Week</Form.Label>
                            </div>
                        </Col>
                        <Col xs = {12} className = "mb-3">
                            <div className = "radio-row d-flex align-items-center">
                                <input  name = "radio" type = "radio" className = "form-check-input mt-0" id = "always"/>
                                <Form.Label htmlFor = "always" className = "mb-0 ms-2">Alwasy</Form.Label>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button className = "btn-main-default" onClick={()=>setMuteShow(false)}>
                        Cancel
                    </Button>
                    <Button  className = "btn-main" onClick={()=>setMuteShow(false)}>
                        Mute Notification
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={dtshow} onHide={() => setDtShow(false)} className = "whatsapp-modal confirm-modal modal-contact" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title>Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className = "text-content">
                        You are about to delete <span className = "u-name">“ Fahad “</span> from your contacts. This process cannot be reversed.
                    </div>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button variant = "danger-light" onClick={() => setDtShow(false)}>
                        Yes, Delete it
                    </Button>
                    <Button  variant = "danger" onClick={() => setDtShow(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={dtmshow} onHide={() => setDtmShow(false)} className = "whatsapp-modal confirm-modal modal-contact" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title>Delete Message?</Modal.Title>
                </Modal.Header>
                <Modal.Footer className = "px-0">
                    <Row className = "w-100">
                        <Col sm = {12} className = "text-end">
                            <Button variant = "danger-light" onClick={() => setDtmShow(false)}>
                                Delete for everyone
                            </Button>
                        </Col>
                    </Row>
                    <Row className = "w-100">
                        <Col sm = {12} className = "text-end">
                            <Button variant = "danger-light" onClick={() => setDtmShow(false)}>
                                Delete for me
                            </Button>
                        </Col>
                    </Row>
                    <Row className = "w-100">
                        <Col sm = {12} className = "text-end">
                            <Button  variant = "danger" onClick={() => setDtmShow(false)}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            <Modal show={atsshow} onHide={() => setAtsShow(false)} className = "whatsapp-modal modal-g-contact modal-select-assignTo" centered>
                <Modal.Header className = "pt-0 px-0" closeButton>
                    <Modal.Title className = "mb-3 w-100">
                        Assign to:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className = "pt-0">
                    
                        <Row>
                            <Col sm = {12}>
                                <Form.Group>
                                    <Form.Label>Filter users by choosing Tags</Form.Label>
                                    <Select className = "c-select basic-multi-select" options={options} placeholder = {'Choose Tag'} classNamePrefix="select"/>
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className = "modal-body-inner">
                            <Row>
                                <div className = "col-12">
                                    <AssignContact/>
                                </div>
                            </Row>
                        </div>

                        <Row className = "pt-3">
                            <Col xs = {12}>
                                <Button variant = "main" className = "d-block w-100" onClick = {()=>setAtsShow(false)}>
                                    Save Assignment
                                </Button>
                            </Col>
                        </Row>
                    
                </Modal.Body>
            </Modal>

            <Modal show={editshow} onHide={() => setEditShow(false)} className = "Edit_modal modal-contact" centered>
                <Modal.Header>
                    <ul className = "nav align-items-center justify-content-between w-100">
                        <li className = "nav-item">
                            Edit Message
                        </li>
                        <li className = "nav-item">
                            <div className = "hide-editModal" onClick = {()=>setEditShow(false)} style = {{cursor:'pointer'}}>
                                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.3926 11.6074L11.6075 23.3925" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M23.3926 23.3926L11.6075 11.6075" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </li>
                    </ul>
                </Modal.Header>
                <Modal.Body className = "p-0">
                    <div className = "message-edit-content">
                        <div className = "edit-content-area">
                            <div className = "edit-msg">
                                <div className = "edit-msg-body">
                                    Whether you're looking to earn or expand your project's impact, our platform offers a diverse range of tasks and micro jobs tailored to fit your needs.Whether you're looking to earn or expand your project's impact, our platform offers a diverse range of tasks and micro jobs tailored to fit your needs.....
                                    <div className = "read-more">
                                        <button className = "rm">
                                            Read More
                                        </button>
                                    </div>
                                </div>
                                <div className = "edit-msg-time-status">
                                    <div className = "chat-received">
                                        <img src = {Chat_seen} alt = "chat-seen-icons"/>
                                    </div>
                                    <div className = "chat-time">
                                        9:22 PM
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className = "msg-area">
                            <div className = "input-msg w-100">
                                <input type = "text" value = "Hello Here"/>
                            </div>
                            <div className = "cmd-side">
                                <button className = "btn-send" onClick = {()=>setEditShow(false)}>
                                    <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.07129 10.786L10.9284 14.6431L21.2501 4.35742" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={selectTemplatehow} onHide={() => setSelectTemplateShow(false)} className = "whatsapp-modal select_tempalte-modal" centered>
                <Modal.Header className = "border-0" closeButton>
                    <Modal.Title className = "mb-0 w-100">
                        Select a Template
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className = "pt-0">
                    
                        <Row>
                            <Col sm = {12}>
                                <Form.Group>
                                    <Form.Control type = "text" placeholder = "Search Template" className = "search-template"/>
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className = "modal-body-inner">
                            <Row>
                                <Col md = {7}>
                                    <InsertTemplate/>
                                </Col>
                                <Col md = {5}>
                                    <div className="message-preview-sec">
                                        <div className="message-prreview-inner">
                                            <div className = "mb-3">
                                                <img src = {Chat_banner} alt = "img" className = "w-100"/>
                                            </div>
                                            <div className="start-heading">The event starts at 12 o'clock promptly.</div>
                                            <div className="message-details">Hello everyone, the event starts at 12 o'clock promptly on March 7th, 2023. the event starts at 12 o'clock promptly on March 7th.</div>
                                            <div className="regard-text mb-0">Best Regards</div>
                                        </div>
                                        <Row className = "link-footer mx-0">
                                            <Col xs = {6} className = "px-0">
                                                <a href = "#"> <img src = {Reciver} alt = "icon"/> Call me</a>
                                            </Col>
                                            <Col xs = {6} className = "px-0">
                                                <a href = "#"><img className = "me-1" src = {Linkforward} alt = "icon"/> Register Now</a>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant = "main" onClick = {()=>setSelectTemplateShow(false)}>
                        Insert Template
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={mobselectTemplatehow} onHide={() => setMobSelectTemplateShow(false)} className = "whatsapp-modal select_tempalte-modal" centered>
                <Modal.Header className = "border-0" closeButton>
                    <Modal.Title className = "mb-0 w-100">
                        Select a Template
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className = "pt-0">
                    
                        <Row>
                            <Col sm = {12}>
                                <Form.Group>
                                    <Form.Control type = "text" placeholder = "Search Template" className = "search-template"/>
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className = "modal-body-inner">
                            <Row>
                                <Col md = {12}>
                                    <InsertTemplate/>
                                </Col>
                            </Row>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant = "main" onClick = {()=>setMobSelectTemplateShow(false)} 
                        onClickCapture={()=>setMObTemplateShow(true)}>
                        Insert Template
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={mobtemplateshow} onHide={() => setMObTemplateShow(false)} className = "whatsapp-modal modal-contact preview-template" centered>
                <Modal.Header className = "p-0" closeButton></Modal.Header>
                <Modal.Body className = "py-0">
                    <div className = "preview-sec">
                        <div className = "preview-body">
                            <div className = "preview-screen">
                                <div className = "event-img">
                                    <img src = {Eventimg} alt = "event-img" className = "w-100 mb-2"/>
                                </div>
                                <div className = "heading">
                                    The event starts at 12 o'clock
                                </div>
                                <div className = "timing">
                                    promptly.
                                </div>
                                <div className = "content">
                                    Hello everyone, the event starts at 12 o'clock promptly on March 7th, 2023. the event starts at 12 o'clock promptly 
                                    on March 7th, 2023.the event starts at 12 o'clock promptly.
                                </div>
                                <div className = "heading">
                                    Best Regards
                                </div>
                            </div>
                            <Row className = "link-footer mx-0">
                                <Col xs = {6} className = "px-0">
                                    <a href = "#"> <img src = {Reciver} alt = "icon"/> Call me</a>
                                </Col>
                                <Col xs = {6} className = "px-0">
                                    <a href = "#"><img className = "me-1" src = {Linkforward} alt = "icon"/> Register Now</a>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default Inboxchat;